<template>
  <div class="box">
    <div>
      <p v-for="item in siteList" :key="item.site_id">
        <span class="word-color" :class="{red: clickObj.act1 == item.site_id}" @click="sel(item,1)">{{item.proxy_name}}</span>
      </p>
    </div>
    <div>
      <p v-for="first in firstList" :key="first.cid">
        <el-checkbox
          v-model="first.is_select"
          @change="set(first,first.is_select)"
        ></el-checkbox>
        <span
        class="word-color"
        :class="{
          red: clickObj.act2 == first.cid,
        }"
        @click="sel(first, 2)"
        >{{ first.name }}</span>
      </p>
    </div>
    <div>
      <p v-for="second in secondList" :key="second.cid">
        <el-checkbox
          v-model="second.is_select"
          @change="set(second,second.is_select)"
        ></el-checkbox>
        <span
        class="word-color"
        :class="{
          red: clickObj.act3 == second.cid,
        }"
        @click="sel(second, 3)"
        >{{ second.name }}</span>
      </p>
    </div>
    <div>
      <p v-for="third in thirdList" :key="third.cid">
        <el-checkbox
          v-model="third.is_select"
          @change="set(third, true)"
        ></el-checkbox>
        <span class="word-color">{{ third.name }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    info: { type: Object, default: () => {} },
    siteid: { type: Number, default: null },
  },
  data() {
    return {
      params: {
        site_category_cancel_ids: [],
        site_category_bind_ids: [],
      },
      siteList: [],
      clickObj: {
        act1: undefined,
        act2: undefined,
        act3: undefined,
      },
      firstList: [],
      secondList: [],
      thirdList: [],
      siteTree: {},
    };
  },
  mounted() {
    this.getSite();
    this.siteTree = Object.assign({},this.info)
    for(let key in this.siteTree) {
      this.params.site_category_bind_ids = this.params.site_category_bind_ids.concat(this.siteTree[key])
    }
  },
  methods: {
    getCategory(item, level) {
      this.$axios
        .get(`/common/business/SiteCategory/getList`, {
          params: {
            p_id: item.cid || 0,
            site_id: this.clickObj.act1,
          },
        })
        .then((res) => {
          res.data.forEach((e) => {
            e.is_select = this.params.site_category_bind_ids.includes(e.cid);
          });
          if (level == 1) {
            this.clickObj.act2 = ''
            this.clickObj.act3 = ''
            this.firstList = res.data;
          }
          if (level == 2) {
            this.clickObj.act3 = ''
            this.secondList = res.data;
            if(item.is_select) {
              this.ctlChildren(item)
            }
          }
          if (level == 3) {
            this.thirdList = res.data;
            if(item.is_select) {
              this.ctlChildren(item)
            }
          }
        });
    },
    getSite() {
      this.$axios
        .get(`/common/business/site/getSimplifyList`)
        .then((res) => {
          const arr = ['mercari','booth','yahooauction','surugaya', 'amazon', 'toranoana', 'melonbooks', 'rakutenbooks']
          res.data.forEach(item=>{
            if(!this.siteid && arr.includes(item.proxy_name)) {
              this.siteList.push(item)
            }
            if(this.siteid && item.site_id == this.siteid){
              this.siteList.push(item)
              this.sel(item,1)
            }
          })
        })
    },
    sel(item, type) {
      if (type == 1) {
        this.clickObj.act1 = item.site_id;
        if(!this.siteTree[item.site_id]) {
          this.siteTree[item.site_id] = []
        }
        this.secondList = []
        this.thirdList = []
        this.getCategory({}, type);
        return
      }
      if (type == 2) {
        this.thirdList = []
        this.clickObj.act2 = item.cid;
      }
      if (type == 3) {
        this.clickObj.act3 = item.cid;
      }
      this.getCategory(item, type);
    },
    async set(item, unbind) {
      if(item.is_select) {
        this.addItem(item)
      } else {
        this.remove(item)
      }
      const flag = await this.judge(item)
      if(item.level<3 && unbind != undefined) {
        this.ctlChildren(item)
      }
      if(item.level > 1) {
        this.findParent(item,flag)
        this.trigger(item, flag)
      }
      // window.console.log(`绑定${item.level}------${this.params.site_category_bind_ids}`)
      // window.console.log(`解绑${item.level}------${this.params.site_category_cancel_ids}`)
      this.$emit('change', this.siteTree)
    },
    addItem(item) {
      if(!this.params.site_category_bind_ids.includes(item.cid)) {
        this.params.site_category_bind_ids.push(item.cid)
        this.siteTree[this.clickObj.act1].push(item.cid)
      }
      if(this.params.site_category_cancel_ids.includes(item.cid)) {
        this.params.site_category_cancel_ids.splice(this.params.site_category_cancel_ids.indexOf(item.cid),1)
      }
    },
    remove(item) {
      if(!this.params.site_category_cancel_ids.includes(item.cid)) {
        this.params.site_category_cancel_ids.push(item.cid)
      }
      if(this.params.site_category_bind_ids.includes(item.cid)) {
        this.params.site_category_bind_ids.splice(this.params.site_category_bind_ids.indexOf(item.cid),1)
        this.siteTree[this.clickObj.act1].splice(this.siteTree[this.clickObj.act1].indexOf(item.cid),1)
      }
    },
    findParent(item,flag) {
      if(item.level == 1) return
      let parentList = []
      if(item.level == 2){
        parentList = this.firstList
      }
      if(item.level == 3){
        parentList = this.secondList
      }
      parentList.forEach(e=>{
        if(e.cid == item.p_id) {
          e.is_select = flag
          this.set(e)
        }
      })
    },
    ctlChildren(item) {
      if(item.level == 3) return
      let list = []
      if(item.level == 2){
        list = this.thirdList
      }
      if(item.level == 1){
        list = this.secondList
      }
      list.forEach(e=>{
        if(item.cid == e.p_id) {
          e.is_select = item.is_select
          this.ctlChildren(e)
        }
      })
    },
    clear(list) {
      list.forEach(item=>{
        this.remove(item)
      })
    },
    trigger(item, flag) {
      if(item.level == 1) return
      let list = []
      if(item.level == 2){
        list = this.secondList
      }
      if(item.level == 3){
        list = this.thirdList
      }
      list.forEach(e=>{
        if(e.is_select && !flag){
          this.addItem(e)
        } else {
          this.remove(e)
        }
      })
    },
    judge(item) {
      if(item.level == 1) return
      let list = []
      if(item.level == 2){
        list = this.secondList
      }
      if(item.level == 3){
        list = this.thirdList
      }
      let flag = true
      list.forEach(e=>{
        if(!e.is_select) {
          flag = false
        }
      })
      if(flag) {
        this.clear(list)
      }
      return flag
    }
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  overflow-y: auto;
  & > div {
    width: 100%;
    border: 1px solid #ccc;
    min-height: 50px;
    &:nth-of-type(1) {
      width: 120px;
      flex-shrink: 0;
    }
    & > p {
      padding: 5px;
      border-bottom: 1px solid #ccc;
    }
  }
}
</style>