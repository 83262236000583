<template>
  <div class="non-analysis">
    <header class="non-analysis-header">
      <nav class="op-category-tabs"></nav>
      <el-button type="primary" @click="add()">添加</el-button>
    </header>
    <DragTable
      class="drag-table"
      :listQuery="list"
      :header="header"
      :sort="true"
      @change="handle">
      <template #state="{row}">{{row.state == 1 ? '停用':'启用'}}</template>
      <template #control="{row}">
        <span class="word-color" @click="edit(row)">{{row.state == 1 ? '启用':'停用'}}</span>
        <span class="word-color" @click="add(row)">编辑</span>
        <span class="word-color" @click="del(row)">删除</span>
      </template>
    </DragTable>
    <go-dialog v-model="visible" width="70vw">
      <div>
        <ManageItem title="名称">
          <el-input v-model="params.name" maxlength="6" placeholder="请输入名称"></el-input>
        </ManageItem>
        <ManageItem title="分类选择">
          <category-table :info="site_category" @change="getCategoryInfo"></category-table>
        </ManageItem>
      </div>
      <footer class="dialog-footer">
        <el-button type="primary" @click="visible=false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import CategoryTable from "./components/table.vue";
import ManageItem from "@/components/commonUI/ManageItem";

export default {
  components: { ManageItem, CategoryTable },
  data() {
    return {
      params: {
        id: '',
        name: '',
        category_ids: []
      },
      header: [
        {name: '序号', value: 'sort'},
        // {name: '排序', value: 'sort'},
        {name: '名称', value: 'name'},
        {name: '状态', slot: 'state'},
        {name: '更新时间', value: 'update_time'},
        {name: '操作人', value: 'operator'},
        {name: '操作', slot: 'control'},
      ],
      list: {
        data: []
      },
      site: '',
      visible: false,
      site_category: {}
    }
  },
  watch: {
    visible(val) {
      if(!val) {
        this.clear()
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$axios.get(`/cms/indexPage/goodsConfig/list`)
      .then(res=>{
        this.list.data = res.data
      })
    },
    getCategoryInfo(data) {
      this.params.category_ids = data
    },
    add(data) {
      if(!data) {
        this.visible = true
        return
      }
      this.$axios.get(`/cms/indexPage/goodsConfig/detail/${data.id}`)
      .then(res=>{
        this.visible = true
        this.params.id = res.data.id
        this.params.name = res.data.name
        this.params.category_ids = res.data.category_ids
        this.site_category = res.data.category_ids
      })
      
    },
    async del(data) {
      const confirm = await this.$goDialog('确定要删除吗？')
      if(!confirm) return
      this.$axios.delete(`/cms/indexPage/goodsConfig/delete/${data.id}`)
      .then(res=>{
        this.$message.success(res.msg)
        this.getList()
      })
      
    },
    edit(data) {
      this.$axios.post(`/cms/indexPage/goodsConfig/set_state`,{
        id: data.id,
        state: data.state == 1 ? 2 : 1
      })
      .then(res=>{
        this.$message.success(res.msg)
        this.getList()
      })
      
    },
    async handle(data) {
      try {
        const arr = []
        data.forEach((item,i) => {
          arr.push({ id: item.id, sort: i+1 })
        });
        await this.$axios.post('/cms/indexPage/goodsConfig/sort',{
          sort_list: arr
        })
      } catch (error) {
        this.getList()
        this.$message.error(error)
      }
    },
    clear() {
      for(let key in this.params) {
        this.params[key] = ''
      }
      this.site_category = {}
    },
    confirm() {
      if(!this.params.name) {
        this.$message.warning('请输入商品名称')
        return
      }
      if(this.params.name && !this.params.name.trim()) {
        this.$message.warning('请输入商品名称')
        return
      }
      this.$axios.post(`/cms/indexPage/goodsConfig/save`,this.params)
      .then(res=>{
        this.$message.success(res.msg)
        this.visible = false
        this.getList()
      })
      
    },
  }
}
</script>
<style lang="scss" scoped>
.word-color{
  margin-right: 10px;
}
.dialog-footer{
  margin-top: 20px;
}
.non-analysis-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>